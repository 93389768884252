import React from "react";
import "./Scholarships.css"; // Import CSS file for styling
import { Link } from "react-router-dom";
const Scholarships = () => {
  return (
    <div className="scholarships-page">
      <header>
        <nav>
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <a href="#scholarships">Scholarships</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#Testimonials">Testimonials</a>
            </li>
            {/* Add more sections to the navbar as needed */}
          </ul>
        </nav>
      </header>

      <main>
        <section id="overview">
          <div className="hero">
            <h1>Scholarships</h1>
            <p>Discover opportunities to support your educational journey.</p>
          </div>
        </section>

        <section id="scholarships">
          <h2>Available Scholarships</h2>
          <br></br>

          <div className="scholarships-list">
            <div className="scholarship-item">
              <h3>
                <b>Excellence in Science Scholarship</b>
              </h3>
              <p>
                This scholarship aims to recognize exceptional students with a
                passion for scientific inquiry and innovation. It supports
                undergraduates pursuing degrees in STEM fields, offering
                financial assistance and mentorship opportunities.
              </p>
              <p>
                <b>Criteria for eligibility:</b>
                <ul>
                  <li>
                    Open to Pakistani citizens enrolled in a recognized
                    university.
                  </li>
                  <li>Minimum GPA of 3.5 on a 4.0 scale.</li>
                  <li>
                    Demonstrated commitment to scientific research or projects.
                  </li>
                </ul>
                <br></br>
                <a href="#" className="visit-btn">
                  Learn More
                </a>
              </p>
            </div>
            <div className="scholarship-item">
              <h3>
                <b>Empowerment Through Education Grant</b>
              </h3>
              <p>
                This scholarship is dedicated to empowering individuals from
                underprivileged backgrounds by providing financial aid for
                higher education. It aims to support deserving students who
                exhibit academic promise and leadership qualities.
              </p>
              <p>
                <b>Criteria for eligibility:</b>
                <ul>
                  <li>
                    Open to Pakistani residents with demonstrated financial
                    need.
                  </li>
                  <li>
                    High school seniors or current undergraduates pursuing any
                    field of study.
                  </li>
                  <li>
                    Strong academic performance and active involvement in
                    community service.
                  </li>
                </ul>
                <a href="#" className="visit-btn">
                  Learn More
                </a>
              </p>
            </div>
            <div className="scholarship-item">
              <h3>
                <b>Women in Technology Scholarship</b>
              </h3>
              <p>
                This scholarship encourages and supports women pursuing degrees
                in technology-related fields. It seeks to bridge the gender gap
                in STEM by offering financial assistance and networking
                opportunities.
              </p>
              <p>
                <b>Criteria for eligibility:</b>
                <ul>
                  <li>
                    Open to Pakistani female students enrolled in a
                    technology-related program.
                  </li>
                  <li>
                    Strong academic record in math, science, or technology
                    subjects.
                  </li>
                  <li>
                    Demonstrated leadership or involvement in tech-related
                    initiatives.
                  </li>
                </ul>
                <br></br>
                <a href="#" className="visit-btn">
                  Learn More
                </a>
              </p>
            </div>
            {/* Add more scholarship items */}
          </div>
        </section>
        <section id="Testimonials">
          <h2>Testimonials</h2>
          <br></br>
          <div class="testimonial">
            <blockquote>
              "This scholarship enabled me to explore my academic passions
              freely. I'm grateful for the support that encouraged me to excel."
            </blockquote>
            <p class="testimonial-author">- Ali Abbas, Scholarship Recipient</p>
          </div>

          <div class="testimonial">
            <blockquote>
              "The scholarship not only provided financial aid but also
              motivated me to strive for excellence in my field of study."
            </blockquote>
            <p class="testimonial-author">
              - Ayesha Patel, Scholarship Recipient
            </p>
          </div>

          <div class="testimonial">
            <blockquote>
              "I'm thankful for the opportunities this scholarship opened up. It
              broadened my horizons and empowered me to make a meaningful
              impact."
            </blockquote>
            <p class="testimonial-author">
              - Usman Malik, Scholarship Recipient
            </p>
          </div>
        </section>

        <section id="about">
          <h2>About Scholarships</h2>
          <p>
            A scholarship is financial support awarded to a student, based on
            academic achievement or other criteria that may include financial
            need, for the purpose of schooling. There are various types of
            scholarships – the two most common being merit-based and need-based.
            The donor or department funding the scholarship sets the criteria
            for recipient selection, and the grantor specifically defines how
            the money is to be applied. The funds are used to defray the costs
            of tuition, books, room, board and other expenses directly tied to a
            student’s educational cost through the university.
          </p>
        </section>
        {/* Add more sections with detailed information */}
      </main>

      <footer>
        <p>Contact us for more information about scholarships.</p>
        <p>Email: info@example.com | Phone: +123456789</p>
      </footer>
    </div>
  );
};

export default Scholarships;
