import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import universities from "../universities";
import "./university.css";
import { Link } from "react-router-dom";

function UniversityDetail() {
  const { id } = useParams();
  const university = universities[id];

  if (!university) {
    return <div>University not found</div>;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderPrograms = (programsData) => {
    return (
      <div className="programs-lists">
        {programsData.map((categoryData, index) => (
          <div key={index} className="programs-column">
            <h3>{categoryData.category}</h3>
            <ul>
              {categoryData.programs.map((program, programIndex) => (
                <li key={programIndex}>{program}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };
  const renderResearch = (researchData) => {
    return (
      <div>
        <h2>Research And Innovation</h2>
        {researchData.map((area, index) => (
          <div key={index}>
            <b>{area.title}:</b> {area.content}
            <br />
          </div>
        ))}
      </div>
    );
  };
  const renderCampuses = (campusesData) => {
    return (
      <div>
        <h2>Campuses</h2>
        <p>{university.name} has several campuses located in major cities:</p>
        <ul>
          {campusesData.map((campus, index) => (
            <li key={index}>{campus}</li>
          ))}
        </ul>
        <p>Each campus offers specialized programs and facilities.</p>
      </div>
    );
  };

  return (
    <div>
      <div className="comsats-info">
        <section className="com">
          <nav>
            <ul>
              <li>
                <Link to={"/"} href="#overview" style={{ color: "white" }}>
                  Home
                </Link>
              </li>
              <li>
                <a href="#overview" style={{ color: "white" }}>
                  Overview
                </a>
              </li>
              <li>
                <a href="#programs" style={{ color: "white" }}>
                  Programs
                </a>
              </li>
              <li>
                <a href="#campuses" style={{ color: "white" }}>
                  Campuses
                </a>
              </li>
              <li>
                <a href="#research" style={{ color: "white" }}>
                  Research
                </a>
              </li>
              {/* Add more sections to the navbar as needed */}
            </ul>
          </nav>
        </section>

        <main>
          <section id="overview">
            <h1 style={{ color: "black" }}>{university.name}</h1>
            <p>{university.description}</p>
          </section>

          <section id="programs">
            <h2 style={{ color: "black" }}>Programs Offered</h2>
            {university.sections.map(
              (section, index) =>
                section.type === "Programs Offered" &&
                renderPrograms(section.data)
            )}
          </section>

          <section id="campuses">
            {university.sections.map(
              (section, index) =>
                section.type === "Campuses" && renderCampuses(section.data)
            )}
          </section>

          <section id="research">
            {university.sections.map(
              (section, index) =>
                section.type === "Research and Innovation" &&
                renderResearch(section.data)
            )}
          </section>
          {/* Add more sections with detailed information */}
        </main>

        <footer>
          <p>
            Visit the official website to explore more about {university.name}:
          </p>
          <a
            href={university.link}
            target="_blank"
            rel="noopener noreferrer"
            className="visit-btn"
          >
            Visit Official Site
          </a>
        </footer>
      </div>
    </div>
  );
}

export default UniversityDetail;
