import React, { useReducer, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const ActionTypes = {
  NEXT_SLIDE: "NEXT_SLIDE",
  PREV_SLIDE: "PREV_SLIDE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.NEXT_SLIDE:
      return {
        ...state,
        activeIndex: (state.activeIndex + 1) % action.totalSlides,
      };
    case ActionTypes.PREV_SLIDE:
      return {
        ...state,
        activeIndex:
          (state.activeIndex - 1 + action.totalSlides) % action.totalSlides,
      };
    default:
      return state;
  }
};

function SliderComponent() {
  const containerStyle = {
    width: "100%",
    maxWidth: "900px",
    margin: "5px auto 0",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "500px",
    display: "block",
    margin: "0 auto",
  };

  const initialState = {
    activeIndex: 0,
  };

  const totalSlides = 5; // Update this with the total number of slides

  const [state, dispatch] = useReducer(reducer, initialState);

  const moveToPrevSlide = () => {
    dispatch({ type: ActionTypes.PREV_SLIDE, totalSlides });
  };

  const moveToNextSlide = () => {
    dispatch({ type: ActionTypes.NEXT_SLIDE, totalSlides });
  };

  // Automatically move to the next slide every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      moveToNextSlide();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [state.activeIndex]);

  return (
    <div
      id="imageSlider"
      className="carousel slide "
      data-bs-ride="carousel"
      style={containerStyle}
    >
      <div className="carousel-inner">
        {/* Slide 1 */}
        <div
          className={`carousel-item ${state.activeIndex === 0 ? "active" : ""}`}
          style={{ textAlign: "center" }}
        >
          <img
            src={require("../photos/comsats4.jpg")}
            className="d-block"
            alt="Slide 1"
            style={imageStyle}
          />
          <p
            style={{
              margin: "10px 0",
              fontFamily: "sans-serif",
              fontSize: "1.063rem",
              fontStretch: "condensed",
              fontWeight: "900",
              lineHeight: "1.294",
              textTransform: "uppercase",
            }}
          >
            <b>COMSATS</b>
          </p>
        </div>

        {/* Slide 2 */}
        <div
          className={`carousel-item ${state.activeIndex === 1 ? "active" : ""}`}
          style={{ textAlign: "center" }}
        >
          <img
            src={require("../photos/uet.jpg")}
            className="d-block w-100"
            alt="Slide 2"
            style={imageStyle}
          />
          <p
            style={{
              margin: "10px 0",
              fontFamily: "sans-serif",
              fontSize: "1.063rem",
              fontStretch: "condensed",
              fontWeight: "900",
              lineHeight: "1.294",
              textTransform: "uppercase",
            }}
          >
            <b>U.E.T</b>
          </p>
        </div>

        {/* Slide 3 */}
        <div
          className={`carousel-item ${state.activeIndex === 2 ? "active" : ""}`}
          style={{ textAlign: "center" }}
        >
          <img
            src={require("../photos/nust-1-scaled.jpg")}
            className="d-block w-100"
            alt="Slide 3"
            style={imageStyle}
          />
          <p
            style={{
              margin: "10px 0",
              fontFamily: "sans-serif",
              fontSize: "1.063rem",
              fontStretch: "condensed",
              fontWeight: "900",
              lineHeight: "1.294",
              textTransform: "uppercase",
            }}
          >
            <b>NUST</b>
          </p>
        </div>

        {/* Slide 4 */}
        <div
          className={`carousel-item ${state.activeIndex === 3 ? "active" : ""}`}
          style={{ textAlign: "center" }}
        >
          <img
            src={require("../photos/giki1.png")}
            className="d-block w-100"
            alt="Slide 4"
            style={imageStyle}
          />
          <p
            style={{
              margin: "10px 0",
              fontFamily: "sans-serif",
              fontSize: "1.063rem",
              fontStretch: "condensed",
              fontWeight: "900",
              lineHeight: "1.294",
              textTransform: "uppercase",
            }}
          >
            <b>GIKI</b>
          </p>
        </div>

        {/* Slide 5 */}
        <div
          className={`carousel-item ${state.activeIndex === 4 ? "active" : ""}`}
          style={{ textAlign: "center" }}
        >
          <img
            src={require("../photos/pu2.jpg")}
            className="d-block w-100"
            alt="Slide 5"
            style={imageStyle}
          />
          <p
            style={{
              margin: "10px 0",
              fontFamily: "sans-serif",
              fontSize: "1.063rem",
              fontStretch: "condensed",
              fontWeight: "900",
              lineHeight: "1.294",
              textTransform: "uppercase",
            }}
          >
            <b>PUNJAB UNIVERSITY</b>
          </p>
        </div>
      </div>
      {/* Next and previous buttons */}
      <button
        className="carousel-control-prev"
        type="button"
        onClick={moveToPrevSlide}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <button
        className="carousel-control-next"
        type="button"
        onClick={moveToNextSlide}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <br></br>
      <br></br>
    </div>
  );
}

export default SliderComponent;
