import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  return (
    <div>
      <nav
        class="navbar navbar-expand-md navbar-dark fixed-top bg-light"
        style={{ height: 70 }}
      >
        <div class="container-fluid">
          <a
            className="navbar-brand text-dark"
            style={{
              fontFamily: "sans-serif",
              fontStretch: "condensed",
              fontWeight: "500",
              display: "flex",
              alignItems: "center", // Center the text vertically
            }}
            href="#"
          >
            <img
              src={require("../photos/pakistan1.png")}
              alt="StudyInPakistan Logo"
              style={{ height: "30px", marginRight: "8px" }} // Adjust the height and spacing
            />
            StudyInPakistan
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ms-auto mb-2 mb-md-0">
              <li class="nav-item">
                <a
                  class="nav-link active text-dark"
                  style={{
                    fontFamily: "sans-serif",
                    fontstretch: "condensed",
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text-dark"
                  style={{
                    fontFamily: "sans-serif",
                    fontstretch: "condensed",
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                  href="#university"
                >
                  Universities
                </a>
              </li>
              <Link
                to="./scholarships"
                className="nav-item"
                style={{
                  textDecoration: "none",
                  color: "inherit", // Set the color to inherit to override browser defaults
                }}
              >
                <a
                  className="nav-link text-dark"
                  style={{
                    fontFamily: "sans-serif",
                    fontStretch: "condensed", // Fixed typo here (fontstretch to fontStretch)
                    fontWeight: "500",
                    textTransform: "uppercase",
                    textDecoration: "none",
                  }}
                >
                  Scholarships
                </a>
              </Link>

              <li class="nav-item">
                <a
                  class="nav-link text-dark"
                  style={{
                    fontFamily: "sans-serif",
                    fontstretch: "condensed",
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                  href="#culture"
                >
                  Culture
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
