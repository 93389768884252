import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Why.css"; // Import your custom stylesheet

function Why() {
  return (
    <div className="custom-container-bg">
      <Container fluid>
        <Row className="d-flex align-items-stretch mb-0">
          <Col md={6} className="text-dark px-4 ml-4 ml-md-5">
            {/* Text content goes here */}
            <br></br>
            <h2
              style={{
                fontFamily: "sans-serif",
                fontstretch: "condensed",
                fontWeight: "900",
                textTransform: "uppercase",
                color: "black",
              }}
            >
              Why Pakistan?
            </h2>
            <p>
              <ul
                style={{
                  fontFamily: "sans-serif",
                  fontstretch: "condensed",
                  fontWeight: "500",
                  marginRight: "20px",
                }}
              >
                <li>
                  Studying in Pakistan offers a unique and enriching educational
                  experience that combines academic excellence with cultural
                  diversity.
                </li>
                <li>
                  The country boasts a range of reputable universities and
                  educational institutions that provide quality education across
                  various disciplines.
                </li>
                <li>
                  One compelling aspect of studying in Pakistan is the rich
                  cultural tapestry that students are immersed in.{" "}
                </li>
                <li>
                  From historical landmarks to vibrant traditions, students have
                  the opportunity to explore a diverse and dynamic society,
                  gaining insights that go beyond the classroom.
                </li>
              </ul>
            </p>
          </Col>
          <Col md={6} className="px-0">
            {/* Image goes here */}
            <img
              src={require("../photos/Pakistan.jpg")}
              alt="Your Image"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Why;
