import React from "react";
import SliderComponent from "../components/Features";
import Why from "../components/Why";
import Culture from "../components/Culture";
import UniversityGrid from "../components/UniversitySlider";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Home() {
  return (
    <div>
      <Header />
      <SliderComponent />
      <Why />
      <Culture />
      <UniversityGrid />
      <Footer />
    </div>
  );
}

export default Home;
