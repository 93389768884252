import biryani from "../photos/biryani.jpg";
import chapli from "../photos/chapli.jpg";
import nihari from "../photos/nihari.jpg";
import haleem from "../photos/haleem.jpg";
import React, { useEffect } from "react";
import "./Flavors.css";
import { Link } from "react-router-dom";

const FlavorsOfPakistanPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flavors-container">
      <header className="header">
        <div className="logo">Flavors of Pakistan</div>
        <nav>
          <ul>
            <Link
              to={"/"}
              href="#overview"
              style={{ color: "white", textDecoration: "none" }}
            >
              Home
            </Link>
            <li>
              <a href="#dishes" style={{ color: "white" }}>
                Dishes
              </a>
            </li>
            <li>
              <a href="#local" style={{ color: "white" }}>
                Local Specialties
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main className="main-content">
        <section className="hero-section">
          <p>
            Discover the diverse and vibrant flavors of Pakistani cuisine, a
            delectable blend of spices, aromas, and culinary traditions that
            reflect the country's rich cultural heritage.Diverse and vibrant
            flavors of Pakistani cuisine, a delectable blend of spices, aromas,
            and culinary traditions that reflect the country's rich cultural
            heritage.
          </p>
        </section>

        <section className="content-section">
          <h2 id="dishes">Delicious Dishes</h2>

          <div className="place-cards">
            <div className="place-card">
              <img src={biryani} alt="Food Item 1" />
              <h3>Biryani</h3>
              <p>
                Biryani is a fragrant rice dish cooked with succulent meat,
                aromatic spices, and saffron-infused rice. It's a popular dish
                known for its rich flavors and regional variations across
                Pakistan. It's often served with raita and salad.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="visit-btn"
              >
                Learn More
              </a>
            </div>

            <div className="place-card">
              <img src={haleem} alt="Food Item 2" />
              <h3>Haleem</h3>
              <p>
                Haleem is a slow-cooked stew made from a blend of wheat, barley,
                lentils, and meat (usually beef or chicken). It's seasoned with
                aromatic spices and served with garnishes like lemon wedges,
                coriander, and fried onions.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="visit-btn"
              >
                Learn More
              </a>
            </div>
            {/* Add more food items */}
          </div>
        </section>

        <section className="landscape-section">
          <h2 id="local">Local Specialties</h2>

          <div className="landscape-cards">
            <div className="landscape-card">
              <img src={nihari} alt="Specialty 1" />
              <h3>Nihari</h3>
              <p>
                Nihari is a slow-cooked stew made with tender beef or lamb,
                simmered with aromatic spices until the meat is tender and
                flavorful. It's often served with naan bread or rice.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="visit-btn"
              >
                Learn More
              </a>
            </div>

            <div className="landscape-card">
              <img src={chapli} alt="Specialty 2" />
              <h3>Chapli Kebab</h3>
              <p>
                Chapli Kebab is a type of kebab made from minced meat, spices,
                and herbs. It's flattened into round patties and pan-fried,
                giving it a unique taste. It's typically served with naan or
                rice and chutney.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="visit-btn"
              >
                Learn More
              </a>
            </div>
            {/* Add more specialties */}
          </div>
        </section>
      </main>

      <footer className="footer">
        <p>&copy; 2023 StudyInPakistan. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default FlavorsOfPakistanPage;
