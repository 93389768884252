import qawali from "../photos/qawali.jpg";
import ghazal from "../photos/ghazal.jpg";
import pop from "../photos/pop.jpg";
import rock from "../photos/rock.jpg";
import React, { useEffect } from "react";
import "./music.css";
import { Link } from "react-router-dom";

const MelodiesOfPakistanPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="melodies-of-pakistan-container">
      <header className="header">
        <div className="logo">Melodies of Pakistan</div>
        <nav>
          <ul>
            <Link
              to={"/"}
              href="#overview"
              style={{ color: "white", textDecoration: "none" }}
            >
              Home
            </Link>
            <li>
              <a href="#traditional" style={{ color: "white" }}>
                Traditional Music
              </a>
            </li>
            <li>
              <a href="#local-music" style={{ color: "white" }}>
                Local Music
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main className="main-content">
        <section className="hero-section">
          <p>
            Explore the diverse and enchanting melodies of Pakistani music, a
            harmonious blend of traditional and contemporary tunes that resonate
            with the country's cultural vibrancy.
          </p>
        </section>

        <section className="content-section">
          <h2 id="traditional">Traditional Music</h2>

          <div className="place-cards">
            <div className="place-card">
              <img src={qawali} alt="Music Item 1" />
              <h3>Qawwali</h3>
              <p>
                Qawwali is a form of devotional music characterized by its Sufi
                lyrics, rhythmic handclaps, and soulful melodies. It's a
                traditional musical expression that inspires spiritual
                connection and ecstasy.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="visit-btn"
              >
                Learn More
              </a>
            </div>

            <div className="place-card">
              <img src={ghazal} alt="Music Item 2" />
              <h3>Ghazal</h3>
              <p>
                Ghazal is a poetic form of music that expresses themes of love,
                loss, and beauty. It's characterized by its profound lyrics and
                soul-stirring melodies sung in classical and semi-classical
                styles.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="visit-btn"
              >
                Learn More
              </a>
            </div>
            {/* Add more food items */}
          </div>
        </section>

        <section className="landscape-section">
          <h2 id="local-music">Local Music</h2>

          <div className="landscape-cards">
            <div className="landscape-card">
              <img src={pop} alt="Specialty 1" />
              <h3>Pop Music</h3>
              <p>
                Pop music in Pakistan incorporates modern beats and catchy
                tunes, blending local influences with international sounds. It's
                a popular genre enjoyed by diverse audiences across the country.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="visit-btn"
              >
                Learn More
              </a>
            </div>

            <div className="landscape-card">
              <img src={rock} alt="Specialty 2" />
              <h3>Rock Music</h3>
              <p>
                Rock music in Pakistan has a growing fanbase, characterized by
                its energetic rhythms, electrifying guitar solos, and
                thought-provoking lyrics. It's a genre that resonates with the
                youth and music enthusiasts.
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="visit-btn"
              >
                Learn More
              </a>
            </div>
            {/* Add more specialties */}
          </div>
        </section>
      </main>

      <footer className="footer">
        <p>&copy; 2023 StudyInPakistan. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default MelodiesOfPakistanPage;
