import React from "react";
import universities from "../universities";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

function UniversityGrid({ customImageStyle }) {
  const defaultImageStyle = {
    width: "100%",
    height: "200px",
    borderRadius: "md",
  };

  const imageStyle = customImageStyle || defaultImageStyle;

  // Group universities into sets of three
  const universitiesSets = [];
  for (let i = 0; i < universities.length; i += 3) {
    universitiesSets.push(universities.slice(i, i + 3));
  }

  return (
    <section id="university" className="container mt-5">
      <div className="row">
        <div className="col-md-12">
          <h2
            className="mb-4"
            style={{
              fontFamily: "sans-serif",
              fontStretch: "condensed",
              fontWeight: "500",
              textTransform: "uppercase",
              color: "black",
            }}
          >
            Explore Universities
          </h2>
        </div>
      </div>
      <Carousel>
        {universitiesSets.map((universitiesSet, setIndex) => (
          <Carousel.Item key={setIndex}>
            <div className="row">
              {universitiesSet.map((university, index) => (
                <div key={index} className="col-md-4 mb-4">
                  <div className="card h-100">
                    <img
                      src={university.imageUrl}
                      alt={university.name}
                      className="card-img-top"
                      style={imageStyle}
                    />
                    <div className="card-body d-flex flex-column">
                      <h5
                        className="card-title"
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "1.063rem",
                          fontStretch: "condensed",
                          fontWeight: "500",
                          lineHeight: "1.294",
                        }}
                      >
                        {university.name}
                      </h5>
                      <p
                        className="card-text"
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "1.063rem",
                          fontStretch: "condensed",
                          fontWeight: "500",
                          lineHeight: "1.294",
                        }}
                      >
                        {university.faculties} faculties
                      </p>
                      <Link
                        to={`/university/${university.id}`}
                        className="mt-auto btn btn-primary"
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export default UniversityGrid;
