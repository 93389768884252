import React from "react";

function Footer() {
  return (
    <footer className=" text-light py-3 bg-light" style={{ height: 80 }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-start text-dark">
            <p>&copy; 2023 StudyInPakistan.All Rights Reserved.</p>
          </div>
          <div className="col-md-6 text-end">
            <a href="#" className=" me-3">
              Privacy Policy
            </a>
            <a href="#" className=" me-3">
              Terms of Service
            </a>
            <a href="#" className="">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
