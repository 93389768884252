import React from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import SliderComponent from "./Features";
import MelodiesOfPakistanPage from "../pages/Music";
import FlavorsOfPakistanPage from "../pages/Flavors";
import "../styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Scholarships from "../pages/Scholarships";
import ColorsOfTraditionPage from "../pages/tradition";
import UniversityDetail from "../pages/ComsatsInfo";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/university/:id" element={<UniversityDetail />} />
          <Route
            path="/melodies-of-pakistan"
            element={<MelodiesOfPakistanPage />}
          />
          <Route
            path="/colors-of-tradition"
            element={<ColorsOfTraditionPage />}
          />
          <Route
            path="/flavors-of-pakistan"
            element={<FlavorsOfPakistanPage />}
          />
          <Route path="/scholarships" element={<Scholarships />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
