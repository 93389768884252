import React from "react";
import { Link } from "react-router-dom";

function Culture() {
  return (
    <section id="culture">
      <div className="container mt-0">
        <h2
          className="mb-4"
          style={{
            fontFamily: "sans-serif",
            fontStretch: "condensed",
            fontWeight: "900",
            textTransform: "uppercase",
            color: "black",
          }}
        >
          Get inspired
        </h2>
        <div className="row">
          <div className="col-md-4 mb-5">
            <Link
              to="/melodies-of-pakistan"
              className="card"
              style={{ textDecoration: "none", position: "relative" }}
            >
              <img
                src={require("../photos/music.jpeg")}
                className="card-img-top"
                alt="Culture 1"
                style={{ height: "300px" }}
              />
              <div className="card-body" style={{ height: "100px" }}>
                <h5
                  className="card-title"
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "1.063rem",
                    fontStretch: "condensed",
                    fontWeight: "900",
                    lineHeight: "1.294",
                    textTransform: "uppercase",
                  }}
                >
                  Melodies of Pakistan
                </h5>
              </div>
            </Link>
          </div>

          <div className="col-md-4 mb-5">
            <Link
              to="/colors-of-tradition"
              className="card"
              style={{ textDecoration: "none", position: "relative" }}
            >
              <img
                src={require("../photos/monument.jpeg")}
                className="card-img-top"
                alt="Culture 2"
                style={{ height: "300px" }}
              />
              <div className="card-body" style={{ height: "100px" }}>
                <h5
                  className="card-title"
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "1.063rem",
                    fontStretch: "condensed",
                    fontWeight: "900",
                    lineHeight: "1.294",
                    textTransform: "uppercase",
                  }}
                >
                  Colors of Tradition
                </h5>
              </div>
            </Link>
          </div>

          <div className="col-md-4 mb-5">
            <Link
              to="/flavors-of-pakistan"
              className="card"
              style={{ textDecoration: "none", position: "relative" }}
            >
              <img
                src={require("../photos/flavors.jpg")}
                className="card-img-top"
                alt="Culture 3"
                style={{ height: "300px" }}
              />
              <div className="card-body" style={{ height: "100px" }}>
                <h5
                  className="card-title"
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "1.063rem",
                    fontStretch: "condensed",
                    fontWeight: "900",
                    lineHeight: "1.294",
                    textTransform: "uppercase",
                  }}
                >
                  Flavors of Pakistan
                </h5>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* Add the hover effect for all cards */}
      <style>
        {`
          .card:hover {
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
            transition: box-shadow 0.3s ease-in-out;
          }
        `}
      </style>
    </section>
  );
}

export default Culture;
