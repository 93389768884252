const universities = [
  {
    id: 0,
    name: "Comsats University Islamabad",
    faculties: 27,
    imageUrl: require("./photos/comsats1.jpeg"),
    link: require("./pages/ComsatsInfo"),
    description:
      "COMSATS University Islamabad (CUI) is a renowned institution in Pakistan with multiple campuses across the country. It is recognized by the Higher Education Commission (HEC) of Pakistan and offers diverse programs catering to various fields including computer science, engineering, business administration, and more. The university emphasizes research and innovation, fostering an environment conducive to academic excellence.",
    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Lahore", "Karachi", "Islamabad", "Peshawar"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },
  {
    id: 1,
    name: "University of Engineering And Technology",
    faculties: 34,
    imageUrl: require("./photos/uet1.jpeg"),
    link: require("./pages/ComsatsInfo"),
    description:
      "The University of Engineering and Technology (UET) is a leading institution in Pakistan, known for its excellence in engineering education and research. Recognized for its academic rigor and commitment to innovation, UET offers a comprehensive range of engineering programs across various disciplines. The university fosters an environment of creativity, technological advancement, and scholarly pursuit.",

    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Faisalabad", "Lahore", "Taxila"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Punjab University",
    faculties: 34,
    imageUrl: require("./photos/pu1.jpeg"),
    description:
      "The University of the Punjab is one of the oldest and most prestigious educational institutions in Pakistan, known for its academic excellence and research contributions. Offering a wide array of undergraduate, graduate, and doctoral programs, the university emphasizes holistic education and intellectual growth. The institution has a rich heritage, fostering a culture of critical thinking, innovation, and societal development.",

    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Jhelum", "Lahore", "Gujranwala"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Lahore University Of Management Sciences (LUMS)",
    faculties: 34,
    imageUrl: require("./photos/lums1.jpeg"),
    description:
      "LUMS is a premier institution in Pakistan, renowned for its exceptional academic standards and commitment to fostering leadership and innovation. Offering a diverse range of undergraduate, graduate, and doctoral programs, LUMS focuses on holistic education and research-driven initiatives. The university prides itself on nurturing critical thinking, entrepreneurship, and societal impact.",

    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Lahore", "Karachi", "Islamabad", "Peshawar"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "FAST NUCES",
    faculties: 34,
    imageUrl: require("./photos/fast.jpeg"),
    description:
      "FAST-NUCES is a prestigious institution in Pakistan, known for its focus on computer science and emerging technologies. Offering a variety of undergraduate, graduate, and doctoral programs, FAST-NUCES emphasizes cutting-edge education and industry relevance. The university is committed to producing skilled professionals and contributing to technological advancements.",

    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Lahore", "Karachi", "Islamabad", "Peshawar"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "Information Technology University (ITU)",
    faculties: 34,
    imageUrl: require("./photos/itu.jpeg"),
    description:
      "ITU is a dynamic institution in Lahore, Pakistan, known for its focus on technology, innovation, and interdisciplinary education. Offering diverse undergraduate, graduate, and doctoral programs, ITU aims to produce tech-savvy professionals equipped for industry challenges and societal impact. The university prides itself on fostering an entrepreneurial spirit and pushing the boundaries of technological advancements.",

    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Lahore"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    name: "National University of Sciance And Technology (NUST)",
    faculties: 34,
    imageUrl: require("./photos/nust.jpeg"),
    description:
      "NUST is a leading institution in Pakistan, renowned for its comprehensive educational programs and cutting-edge research. Offering a vast array of undergraduate, graduate, and doctoral programs, NUST focuses on holistic education and technological innovation. The university is committed to excellence in academia, research, and fostering a culture of innovation and leadership.",

    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Islamabad", "Rawalpindi", "Quetta", "Risalpur", "Karachi"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    name: "Ghulam Ishaq Khan Institute (GIKI)",
    faculties: 34,
    imageUrl: require("./photos/giki.jpeg"),
    description:
      "GIKI is a prestigious institution known for its dedication to engineering education and research. Situated in Topi, Swabi, Khyber Pakhtunkhwa, Pakistan, GIKI was established in 1988 with a focus on providing top-quality education in engineering and technology.",

    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Topi", "Swabi"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },
  {
    id: 8,
    name: "University Of Management And Technology (UMT)",
    faculties: 34,
    imageUrl: require("./photos/umt.jpeg"),
    description:
      "UMT is a renowned institution in Lahore, Pakistan, known for its dedication to providing quality education and fostering innovation and leadership. Offering a diverse range of undergraduate, graduate, and doctoral programs, UMT emphasizes industry relevance and practical learning. The university is committed to producing professionals equipped with the skills to thrive in a dynamic global environment.",

    sections: [
      {
        type: "Programs Offered",
        data: [
          {
            category: "Undergraduate Programs",
            programs: [
              "BS Computer Science",
              "BS Software Engineering",
              "BS Artificial Intelligence",
              "BS Business Administration",
              "BS Accounting & Finance",
              "BS Economics",
              "BS Chemistry",
              "BS Physics",
              "BS Media and Communication Studies",
              "BS Psychology",
              "BS English (Language & Literature)",
              "BS Mathematics",
              "BS Statistics",
              "Doctor of Pharmacy (Pharm D)",
              "BS Chemical Engineering",
              "BS Computer Engineering",
              "BS Electrical Engineering",
              "Bachelor of Design",
              "Bachelor in Interior Design",
              "Bachelor of Architecture",
            ],
          },
          {
            category: "Graduate Programs",
            programs: [
              "MS Management Science",
              "MS Project Management",
              "MS Physics",
              "MS Statistics",
              "MS Mathematics",
              "MS Computer Science",
              "MS Chemical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Electrical Engineering (Offered with both thesis/Non-thesis Option)",
              "MS Computer Engineering",
              "MS Energy Systems Engineering",
              "MS English (Linguistics & Literature)",
              "MS Economics",
              "MS Chemistry",
              "MS Energy & Environmental Engineering",
              "MS Clinical Psychology (Not offered in Spring 2024)",
              "MS Pharmacy",
            ],
          },
          {
            category: "PhD Programs",
            programs: [
              "PhD. Statistics",
              "PhD. Computer Science",
              "PhD. Chemical Engineering",
              "PhD. Electrical Engineering",
              "PhD. Computer Engineering",
              "PhD. Management Science",
              "PhD. Mathematics",
              "PhD. Chemistry",
              "PhD. Economics",
            ],
          },
        ],
      },
      {
        type: "Campuses",
        data: ["Lahore"],
      },
      {
        type: "Research and Innovation",
        data: [
          {
            title: "Computer Science and Engineering",
            content:
              "Our research efforts are dedicated to exploring innovative solutions in cutting-edge areas such as Artificial Intelligence, Machine Learning, Computer Vision, and Data Science.",
          },
          {
            title: "Energy & Environment",
            content:
              "As part of our commitment to sustainability and environmental stewardship, our research is focused on developing renewable energy sources, reducing carbon emissions, and mitigating the impacts of climate change.",
          },
          {
            title: "Health Sciences",
            content:
              "Our researchers are dedicated to advancing healthcare delivery and outcomes through innovative research in areas such as Bone Repair & Regeneration, Dental Materials, Biosensors, and Soft Tissue Engineering.",
          },
          {
            title: "Natural Sciences",
            content:
              "We strive to push the boundaries of human understanding in physics, chemistry, and mathematics, seeking new insights into the workings of the natural world.",
          },
          {
            title: "Social Sciences",
            content:
              "Our research team is focused on addressing the pressing social and economic issues facing Pakistan, such as poverty, inequality, and unemployment, through interdisciplinary research and collaboration.",
          },
          {
            title: "Architecture and Design",
            content:
              "Our research team is dedicated to exploring innovative solutions in the fields of architecture and design, with a focus on sustainability, functionality, and aesthetics. Our research initiatives aim to address the ever-evolving needs of modern society and create sustainable and functional built environments.",
          },
        ],
      },
    ],
  },

  // Add more universities with their sections as needed
];

export default universities;
