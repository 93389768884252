// ColorsOfTraditionPage.js
import image1 from "../photos/image1.jpg";
import image2 from "../photos/image2.jpg";
import image3 from "../photos/image3.jpg";
import image4 from "../photos/image4.jpg";
import React, { useEffect } from "react";
import "./tradition.css";
import { Link } from "react-router-dom";

const ColorsOfTraditionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="colors-of-tradition-container">
      <header className="header tradition">
        <div className="logo">Colors of Tradition</div>
        <nav>
          <ul>
            <Link to={"/"} style={{ color: "white", textDecoration: "none" }}>
              Home
            </Link>
            <li>
              <a href="#places" style={{ color: "white" }}>
                Places
              </a>
            </li>
            <li>
              <a href="#places" style={{ color: "white" }}>
                Culture
              </a>
            </li>
            <li>
              <a href="#history" style={{ color: "white" }}>
                History
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main className="main-content">
        <section className="hero-section">
          <h1 id="places">Traditions and Historical Places</h1>
          <p>
            Immerse yourself in the rich tapestry of traditions and explore the
            historical wonders of Pakistan. Each tradition and monument carries
            a story, a legacy, and a connection to the cultural heritage of this
            beautiful nation.
          </p>
        </section>

        <section className="content-section">
          <h2>Preserving Cultural Heritage</h2>
          <p>
            From the ancient rituals in remote villages to the vibrant
            festivities in bustling cities, Pakistan is a mosaic of traditions
            that have withstood the test of time. The preservation of cultural
            heritage is evident in every aspect of daily life.
          </p>

          <div className="place-cards">
            <div className="place-card">
              <img src={image1} alt="Historical Monument" />
              <h3>Mohenjo-daro</h3>
              <p>
                Explore the archaeological marvel of Mohenjo-daro, an ancient
                city that dates back to the Indus Valley Civilization.
                <br></br>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="visit-btn"
                >
                  Learn More
                </a>
              </p>
            </div>

            <div className="place-card">
              <img src={image2} alt="Cultural Festival" />
              <h3>Lahore Fort</h3>
              <p>
                Visit the Lahore Fort, a UNESCO World Heritage Site, and witness
                the blend of Mughal and Sikh architectural styles.
                <br></br>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="visit-btn"
                >
                  Learn More
                </a>
              </p>
            </div>
          </div>
        </section>

        <section className="landscape-section">
          <h2 id="history">Historical Wonders</h2>
          <p>
            As you traverse the diverse landscapes of Pakistan, you'll encounter
            historical wonders that narrate tales of the country's rich past.
            Each monument stands as a testament to the resilience and creativity
            of its people.
          </p>

          <div className="landscape-cards">
            <div className="landscape-card">
              <img src={image3} alt="Ancient Ruins" />
              <h3>Taxila</h3>
              <p>
                Step back in time in Taxila, an ancient city with ruins that
                span centuries, reflecting the influence of Buddhism and ancient
                civilizations.
                <br></br>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="visit-btn"
                >
                  Learn More
                </a>
              </p>
            </div>

            <div className="landscape-card">
              <img src={image4} alt="Historical Mosque" />
              <h3>Badshahi Mosque</h3>
              <p>
                Admire the grandeur of the Badshahi Mosque in Lahore, an iconic
                symbol of Mughal architecture and one of the largest mosques in
                the world.
                <br></br>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="visit-btn"
                >
                  Learn More
                </a>
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ColorsOfTraditionPage;
